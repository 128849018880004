.coockie-modal-contaner {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  left: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  color: #fff;
  padding: 10px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.8);

  .coockie-content-container {
    text-align: center;
    width: 90%;

    .button {
      margin: 0px 10px 10px;
    }
    .button-blue {
      float: none;
    }

    .remove-note {
      font-size: 12px;
    }
  }
}
.container.secondary-page{
  margin-top: 20px;
}
header{
  box-shadow: 0 0 14px 5px rgba(0, 0, 0, 0.5);
}
.under-construction {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  .under-construction-container {
    background: #fff;
    padding: 60px;
    border-radius: 30px;
  }
}
